.logo-square {
    height: 3em;
    margin: 0em;
    cursor: pointer;
    font-size: 16px;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.side-panel-icon {
    font-size: 1.3em;
    border-radius: 0.2em;
    padding: 0.2em;
    text-align:center;
    cursor: pointer;
    height: 3em;
    width: 3em;
}

.eject-button {
    font-size: 0em !important;
    border-radius: 0.2em;
    padding: 1em;
    width: 70%;
    text-align:center;
    cursor: pointer;
    margin-top: 1em;
    border-radius: 4px;
    margin: 4px;
}

.load-button {
    font-size: 0em !important;
    border-radius: 0.2em;
    padding: 1em;
    width: 70%;
    text-align:center;
    cursor: pointer;
    margin-top: 1em;
    border-radius: 4px;
    margin: 4px;
}

.load-button:hover, .load-button:focus, .reset-visited-area-button:focus, .eject-button:focus {
    border-color: #d9d9d9;
}

.revoke-panel-buttons {
    font-size: 1em;
    border-radius: 3.2em;
    padding: 0.2em;
    text-align:center;
    cursor: pointer;
    height: 3em;
    width: 3em;
}

.initial-div-input-field, .initial-div-input-field:focus, .initial-div-input-field:hover {
    font-size: 1em;
    border-radius: 3.2em;
    padding: 0.2em;
    text-align:center;
    cursor: pointer;
    height: 3em;
    width: 3em;
    background-color: #8e8888;
    color:#1b1b1b;
    border-color: #d9d9d9;
}

.message-input-field, .message-input-field:focus, .message-input-field:hover {
    font-size: 1em;
    padding: 0.2em;
    border-radius: 3.2em;
    cursor: pointer;
    height: 3em;
    width: 3em;
    background-color: #8e8888;
    color:#1b1b1b;
    border-color: #d9d9d9;
}

.revoke-panel-buttons:hover, .reset-visited-area-button:hover, .side-panel-icon:hover, .eject-button:hover {
    border-color: #d9d9d9;
    background-color: #353535 !important;
}

.revoke-panel-buttons:focus {
    border-color: #d9d9d9;
    background-color: #353535 !important;
}

.side-panel-icon:focus {
    border-color: #d9d9d9;
}

.side-panel-icon-text {
    font-size: 10px;
}

.side-panel-icon-open, .side-panel-icon-open:hover, .side-panel-icon-open:active {
    border-color: #d9d9d9;
    background-color: #d9d9d9 !important;
}

.currently-ongoing-preview, .currently-ongoing-preview:hover, .currently-ongoing-preview:active {
    border-color: #c2c1c1;
    background-color: #c2c1c1 !important;
}

.scheduled-preview, .scheduled-preview:hover, .scheduled-preview:active {
    border-color: #6d6a6a;
    background-color: #6d6a6a !important;
}

.preview-taken, .preview-taken:hover, .preview-taken:active {
    border-color: blue;
}

.side-panel-icon-closeable-handle {
    font-size: 20px;
    position: absolute;
    right: 0.2em;
    cursor: pointer;
}

.full-height {
    height: 100vh;
}

.relative-position {
    position: relative;
}

.place-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
}

.mode-style {
    position: absolute;
    left: 0;
    bottom: 70px;
    pointer-events: none;
}

.overflow-wrap-text {
    white-space: pre;
}

.settings-box-livemode {
    position: absolute;
    right: -1012px;
    bottom: 11px;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.3) !important;
    z-index: 2147483640;
    border-radius: 0.2em;
    width: 1000px;
    background-color: #3a3a3a;
    color: white;
    text-align: left;
}

.selected-style, .selected-style:focus, .selected-style:hover {
    color: #1b1b1b !important;
}

.unselected-style, .unselected-style:focus, .unselected-style:hover {
    color: #d9d9d9 !important;
    background-color: #1b1b1b;
}

.custom-select-background-color .ant-select-selection__placeholder {
    color : white;
}

.ant-progress-inner-custom .ant-progress-inner {
    border-radius: 0px !important;
}

.ant-progress-inner-custom .ant-progress-bg {
    border-radius: 0px !important;
    height: 3px !important;
}

.align-center {
    text-align: center;
}

.sidepanel-background-color {
    background: #1b1b1b;
}

.main-background-color {
    background: #111111;
}

.card-background-color {
    background: #3a3a3a;
}

.card-color {
    color: #3a3a3a;
}

.loading-message-livemode {
    position: absolute;
    left: 37.5%;
    bottom: 3.5%;
    border: 1px solid #d9d9d9;
    padding: 7px;
    border-radius: 15px;
    width: 300px;
    text-align: center;
}

.settings-heading {
    text-align: center;
    margin-top: 10px;
}

.select-slot-component > div > span { 
    font-size: 1.3em;
    color: white;
    margin-top: -10px;
}

.custom-select-background-color .ant-select-selection {
    background-color: #3a3a3a;
}

.zoom-controls-button-live-mode {
    margin-bottom: 0.2em;
    height: 50px;
    width: 50px;
    text-align: center;
    color: var(--theme-text-color);
}

.plus-button-live-mode {
    margin-bottom: 0em;
    border-bottom-left-radius: 0em !important;
    border-bottom-right-radius: 0em !important;
    transition: none
}

.minus-button-live-mode {
    margin-top: 0em;
    border-top-left-radius: 0em !important;
    border-top-right-radius: 0em !important;
}

.zoom-level-button-live-mode {
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 0em !important;
    height:3em;
    line-height: 3em !important;
    padding: 0em;
    font-weight: bold;
    cursor: default !important;
    border: 1px solid transparent;
    border-color:#d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    background-color: #fff;
}

.zoom-controls-icon {
    font-size: 1.3em;
}

.custom-zero-margin-divider .ant-divider-horizontal {
    margin: 0px !important;
}

.custom-top-bottom-margin-divider {
    margin-top: 5px;
    margin-bottom: 7px;
}

.custom-left-right-padding-settings {
    padding: 0px 8px 0px 8px;
}

.settings-close-icon-div {
    cursor: pointer;
    font-size: 20px;
    right: -0.5em;
    top: -0.5em;
    border: 1px solid white;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background: #3a3a3a;
}

.settings-close-icon {
    font-size: 15px;
    top: -3px;
    right: 5px;
    position: absolute;
}

.blur {
    width:100%;
    height:100%;
    background-size:cover;
    pointer-events: none;
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -ms-filter: blur(4px);
    -o-filter: blur(4px);
    filter: blur(4px);
}

.initial-options-div {
    position: absolute;
    left: 20%;
    top: 10%;
    font-size: 1.3em;
    padding: 0.2em;
    text-align:center;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.3) !important;
    width: 60%;
    height: 80%;
    display : flex;
    align-items : center;
    background: #232323;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.livemode-focus-control-button-text {
    font-size: 0.5em;
    text-align: center;
}

.logo-live-mode {
    height: 5em;
    margin: 2em;
    background: #d9d9d9;
    border-radius: 1.5em;
    padding: 0.5em;
}

.live-mode-initial-message {
    margin-bottom: 1em;
    font-family: arial;
    font-size: 1.5em;
    color: #d9d9d9;
}

.initial-div-error-message {
    font-size: 1em;
    color: #d9d9d9 !important;
    align-self: center;
    padding-top: 0.5em;
    padding-left: 1.5em;
}

.pointer-events-none {
    pointer-events: none;
}

.chat-box-livemode {
    position: absolute;
    right: -762px;
    top: 27%;
    box-shadow: 0 0 7px 0 rgb(0 0 0 / 30%) !important;
    z-index: 2147483640;
    border-radius: 0.2em;
    width: 750px;
    background-color: #3a3a3a;
    color: white;
    text-align: left;
}

.messages-div {
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

.messages-div::-webkit-scrollbar {
    display: none;
}

.selected-slot-style, .selected-slot-style:hover, .selected-slot-style:active {
    text-decoration: underline !important;
    background-color: white !important;
}

