.login-form {
    margin-top:10vh !important;
    padding: 2em;
    background-color: white !important;
    width: var(--login-div-size, 20em);
    margin: auto;
}
  
.login-logo {
    margin: auto;
    display: block;
    margin-bottom: 0.75em;
    background-color: white !important;
    border-radius: 1em !important;
    height: auto;
    width: 30%;
}

.login-form-button {
    height: 45px !important;
    width: 100%;
    margin-top: 1%;
    background-color: #330470;
    border-color: #330470;
}

.login-form-button:hover {
    background-color: #006090;
    border-color: #006090;
}

.demo-login {
    text-align: center;
    font-size: 12px;
}

.logout-message {
    text-align: center;
    margin: auto;
    padding: 10px;
}
