*{
    -webkit-tap-highlight-color: transparent !important;
    -webkit-font-smoothing: antialiased !important;
}

.header-mp{
    height: 60px;
    background-color: #ffffff !important;
}

.list-item-link:hover {
    color: black;
    text-decoration: underline;
}

.list-item-link {
    overflow:hidden;
    width:auto;
    cursor: pointer;
    padding-right: 50%;
}

.ant-list-item-action {
    margin-left:auto;
}

.generic-list-item {
    padding-left: 2em;
}

.add-to-list-icon {
    font-size: 3em;
    text-align: center;
}

.ant-card-meta-description {
    height: 100px;
}

.add-to-list-text {
    font-size: 1em;
    text-align: center;
    font-weight: normal;    
}

.ant-card-meta-description {
    height:100px;
}

.header-comp {
  margin : 5px !important;
  position: relative !important;
  height:3em !important;   
}

.header-style-1 {
    font-size: 2em;
    vertical-align: middle;
    text-align: center
}

.logo-mp{
    height: 100%;
    padding: 0.5em;
    position: absolute;
    top: 60%;
    left: 50%; 
    transform: translate(-50%, -50%);
}

.profile-img-mp{
    margin-top: 10px !important;
    -webkit-box-shadow: 0px 0px 28px 0px rgba(52,49,74,0.32);
    -moz-box-shadow: 0px 0px 28px 0px rgba(52,49,74,0.32);
    box-shadow: 0px 0px 28px 0px rgba(52,49,74,0.32);
    background-color: var(--main-bg-color) !important;
}

.profile-icon-mp{
    font-size: 1rem !important;
    padding-left: 3%;
    padding-top: 15px;
}

.filter-mp{
    background-color: var(--main-bg-color);      
    border-width: 1px !important;
    border-color: rgba(19, 144, 228, 0.863) !important;
    button{
        background-color: var(--main-bg-color) ;
        color: white;
        border: none;
    }
}

.dashboard {
    // background-color: white;
}

.user-menu-drop-down {
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
    width:100%;
    text-align: right;
}

.scanner-list{
    color: #eeeff8 !important;
    background-color: #eeeff8;
    padding: 15px !important;
}

.viewer-controls{
    height: 105vh;
    background-color: white;
    border-right: 1px solid #f5f5f5;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    text-align: center;
}

.viewer-right-sidebar {
    height: auto;
    background-color: var(--main-bg-color);
    padding:0.5em;
}

.ant-card-body{
    padding: 10px !important;
    font-size: .8rem;    
}

.sidebar-card{
    height: auto;
    background-color: white;
    border-radius: 0.5em;
}

.slide-info {
    margin-top: 0.5em;
}

.ant-card-cover{
    border: 5px solid #ffffff;
}
.header-fixed-mp{
    // position: fixed;
    z-index: 1;
}


// ******* Login Form css form ant design ********


#components-form-demo-normal-login .login-form {
    max-width: 300px;
  }
  #components-form-demo-normal-login .login-form-forgot {
    float: right;
  }
  #components-form-demo-normal-login .login-form-button {
    width: 100%;
  }


// ******* Login Form css form ant design ends  ********



.login-mp{
    padding-top: 5%;
    padding-left: 5%;    
    background-color: #d4d5d9 !important;
    height: 100%;
    padding-right: 5%;
}

.login-logo-mp{
    height: 80px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 15%;
}


form{
    input{
        height: 45px !important;

    }
}

.ant-card{
    border-radius: 5px;
    // min-height: 360px;
}


.device-mp{
    height: auto !important ;
    background-color: var(--main-bg-color);
    border-radius: 3px;
    margin-top: 4% !important;
    margin-bottom: 5%;
}


.scanner-heading-mp{
    text-align: center;
    font-size: 1rem;    
}


.ant-card-cover{
    background-color: #f5f5f5;
}

.whoop-mp{
    height: 100px;

    margin-top: 12%;
}


.link-details-mp{
    color : black;
}

.link-details-mp:hover {
    color : black;
    text-decoration: none;
    text-decoration-line: none
}

.nav-mp{
    height : 100%;
    width : 100%;
}

.ant-slider-mark{
    font-size : 0.6rem !important;
}

.ant-slider-vertical .ant-slider-mark{
    left: 6px !important;
    width: 15px !important;
}

.ant-slider-vertical{
    margin: 25px 6px !important;
    padding: 0 0px !important;
}

.ol-overviewmap-box{
    background-color: blue;
    opacity: .4;
}

.icon-mp{
    font-size: 0.8rem !important;
    padding: 7px;
}
.ant-divider-horizontal{
    margin: 5px 0 !important;
}

.search-sugg-mp{
    background-color: white;
    z-index : 10 ;
    height : auto;
    position: absolute !important;
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
    border-color: rgba(0, 0, 0, 0.09);
}

#shapeone {
    width : 5;
    background-color: blue;
}   

.ant-list-split .ant-list-header{
    border-bottom: none !important;
}

.view-sidebar {
    border-width: 1px !important;
    border-color: rgba(129, 129, 129, 0.863) !important;
    background-color: white !important;
    color: rgb(58, 58, 58) !important;
    // height: 2rem !important;
    height: 8% !important;
    width: 60% !important ;
    border-radius: 5px !important;
    margin: 2px !important;
}

.view-sidebar-selected {
    border-color: rgba(19, 144, 228, 0.863) !important;
    border-width: 2px !important;
}

.view-sidebar:hover{
    // background-color: rgba(19, 144, 228, 0.863) !important;
    //color: white !important;
    border-color: rgba(19, 144, 228, 0.863) !important;
    border-width: 2px !important;
}

.grid-size {
    font-size: 0.8em;
}

.stop-draw-btn {
    height: 8% !important;
    width: 100% !important ;
    border-radius: 5px !important;
    margin: 2px !important;
}

.zoom-btn-mp {
    // height: 2rem !important;
    height: 15% !important;
    width: 65% !important ;
}

.anno-btn-mp > i{
    color: white;
}

.hidden-mp{
    display: none;
}

#delete-anno-mp{
    float: right;
    font-size: .7rem;
    margin-top: 3%;
    color: #4474F3;
    cursor: pointer;
}

.uploadProgress {
    width: 20px !important;
    height: 20px !important;
}

.ant-card-cover{
    min-height : 111px ;
}

.ant-card-meta-description{
    max-height: 100px;
    min-height: 60px;
    overflow-y: auto;
}

#card-tag-mp{
    // max-height: 50px;
    // overflow-y: auto;
    height: 68px;
}

.zoom-slider {
    display: flex;
    height: 200px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.controls-header {
    color: black;
    font-size: .67rem;
    text-align: center;
    padding-top: 5px;
    margin-bottom: 2px;
}

.sidebar-textbutton {
    white-space: normal;
    padding: 0;
    font-size: 11px;
    width: 95% !important;
}

.sidebar-digizoom {
    border-color: #d95a00 !important;
    border-width: 2px !important;
    white-space: normal;
    padding: 0;
    font-size: 11px;
    width: 95% !important;
}

.sidebar-digizoom:hover {
    border-color: #e87902 !important;
    border-width: 3px !important;
}

.controls-text {
    color: black;
    font-size: .6rem;
    text-align: center;
    padding-top: 5px;
}

.editorClass {
    background: white !important;
}
