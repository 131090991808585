.slide-map-container {
    height: calc(var(--vh, 1vh) * 89);
    z-index: 0;
}

.slide-map-container-wbc {
    /* TODO: Spandan Check why width was hard-limited here, we do not need to hard limit width */
    width: 20vw !important;
    height: 86.3vh;
    height: calc(var(--vh, 1vh) * 86.3);
    z-index: 0;
}

.slide-map-container-wbc-40x {
    /* width: 60vw !important; */
    height: 86.3vh;
    height: calc(var(--vh, 1vh) * 86.3);
    z-index: 0;
}

.slide-map-container-full {
    width: 100vw !important;
    height: 94vh;
    height: calc(var(--vh, 1vh) * 94);
    z-index: 0;
}

.slide-map-container-preview {
    width:80vw;
    height:50vh;
    height: calc(var(--vh, 1vh) * 50);
    z-index: 0;
}

.slide-map-original {
    /*width: 100vw !important;*/
    height: 100vh;
    z-index: 0;
}

.slide-map-container-brain {
    width: 96vw !important;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 0;
}

.overlayed-component {
    background-color: var(--overlayed-button-color);
    border-radius: 0.5em;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2), 0px 6px 20px 0px rgba(0, 0, 0, 0.19);
}

.slide-map-row{
    background-color: var(--main-bg-color) !important;
    overflow: hidden;
}

.slide-map-col{
    color: var(--main-bg-color) !important;
    background-color: var(--main-bg-color);
}

.no-pointer-activity {
    pointer-events:none;
}

.stop-presentation, .stop-presentation:hover, .stop-presentation:active, .stop-presentation:focus, .stop-presentation:visited {
    position: absolute;
    pointer-events: all;
    top: 3px;
    left: 45%;
    border-radius: 0px 0px 4px 4px;
    border-left: 3px solid #de3c2f;
    border-right: 3px solid #de3c2f;
    border-bottom: 3px solid #de3c2f;
    border-top: 0px solid #de3c2f;
    z-index: 200;
    background-color: #d3d3d354;
    color: #98251c;
    font-weight: bold;
    background-clip: padding-box;
}

.border-divider-top {
    height: 3px;
    width: 100%;
    top: 0%;
}

.border-divider-left {
    width: 3px;
    height: 100%;
    top: 0%;
    left: 0%;
}

.border-divider-right {
    width: 3px;
    height: 100%;
    top: 0%;
    right: 0%;
}

.border-divider-bottom {
    height: 3px;
    width: 100%;
    bottom: 0%;
    left: 0%;
}

.border-divider {
    background-color: #de3c2f;
    position: absolute;
    z-index: 100;
}

.main-background-color {
    background: #111111;
}

.loading-message-slidemap {
    position: absolute;
    right: 1%;
    top: 1%;
    border: 1px solid #d9d9d9;
    padding: 7px;
    border-radius: 15px;
    width: 300px;
    text-align: center;
    z-index: 1000;
    opacity: 0.5;
}

.tab{
    background-color: #98251c;
}
